<template>
  <div class="content-info">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />
    <Slider :slides="slides" v-slot="{ currentSlide }">
    <!-- <a :href="currentSlide.link" target="_blank"> -->
        <img :alt="currentSlide.title" :title="currentSlide.title" :src="currentSlide.img">
    <!-- </a> -->
    </Slider>
    <TheNav :navLinkList="navLinkList" :classifyList="classifyList"  />
    <div class="info">
      <div class="info-left">
        <img :src="detail.caseImgUrl" :alt="detail.caseName" :title="detail.caseName" />
      </div>
      <div class="info-right info-right-gray">
        <div class="info-content size-12">  <div class="info-title info-title-blue size-18">{{ detail.caseName }}</div>{{ detail.caseContent }}</div>
      </div>
      <div style="clear:both;"></div>
    </div>
    
    <div class="case-target"  v-if="detail.targetSceneList && detail.targetSceneList.length > 0">
      <div class="block-title center size-20 bold-title letter-spacing">客户目标</div>
      <div class="text-doru"></div>
      <div class="target-content">
        <div class="target-item" v-for="(item,key) in detail.targetSceneList" :key="key">
            <div class="target-slider"><img  alt="目标" :src="item.imgUrl" class="target-slider-img"></div>
            <div class="target-slider-desc size-14"><pre>{{ item.sceneContent }}</pre></div>
        </div>
        <div style="clear:both;"></div>
      </div>
    </div>

    <div class="info-solution" v-if="detail.solutionSceneList && detail.solutionSceneList.length > 0">
      <div class="block-title center size-20 bold-title letter-spacing">解决方案</div>
      <div class="text-doru"></div>
      <div class="info">
        <div class="info-left">
          <img :src="detail.solutionSceneList[0] ? detail.solutionSceneList[0].imgUrl : ''" title="解决方案" alt="解决方案" />
        </div>
        <div class="info-right">
          <div class="info-content size-12"><pre>{{ detail.solutionSceneList[0] ? detail.solutionSceneList[0].sceneContent : '' }}</pre></div>
        </div>
        <div style="clear:both;"></div>
      </div>
    </div>

    <TheContact />
  </div>
</template>

<script>
import { getCaseNavList } from "@/utils/common";
import { getCaseList } from "@/api/case/index";
export default {
  name: 'CaseDetail',
  data() {
    return {
      detail:{},
      slides: [
        {
          title: '客户案例',
          img: require('@/assets/case.png'),
        },
      ],
      classifyList:[],
      navLinkList:[],
      show:false,
      showText:'',
      classifyId:0
    }
  },
    watch: {
    // 监听路由是否变化
    '$route' (to) { 
        if(to.params.caseId != this.classifyId){
          this.classifyId = parseInt( this.$route.params.caseId) || 0
          this.init();
        }
      },
  },
   created(){
      this.classifyId = parseInt( this.$route.params.caseId) || 0
      this.init()
    },  
  methods: {
    init() {
      this.getList()
    },
    getList() {
      this.show = true;
      this.showText = "加载中...";
      this.classifyList = []
      this.navLinkList = getCaseNavList()
      getCaseList(this.proParams)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            if(res.data.length > 0) {
              let info 
              res.data.forEach((item) =>  {
                info = item
                info.name = item.caseName
                info.link = {name:'CaseDetail',params:{caseId:item.id}}
                this.classifyList.push(info)
              })

              let cate = []
                cate = this.classifyList.find(item => {return item.id == this.classifyId})
                if(!cate || cate.length == 0) {
                let id = this.classifyId
                if(id) {
                  this.classifyList[0].link = {name:'CaseDetail',params:{caseId:id}}
                }else {
                  this.classifyList[0].link = {name:'CaseIndex'}
                }
                cate = this.classifyList[0]
              }
              console.log(this.classifyId)
              console.log(cate)
              // this.cateTitle = this.classifyList[0].name
              this.navLinkList.push({name:cate.name,link:''})
              this.detail = cate
            }
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    }
  }
}
</script>

<style scoped>
  .info-detail {
    padding:0 10%;
  }

  /* .info-solution .info {
    padding-top:80px;
  } */

  .info-solution {
    padding-top:40px;
    background-color: #f4f8fb;
  }

  .target-content {
    padding:40px 10%;
  }

  .target-item {
    width: 32.3%;
    margin:0 0.5%;
    float: left;
    text-align: center;
  }

  .target-slider {
    position:relative;
    width:100%;
  }

  .target-slider-desc {
    margin-top:20px;
  }

  .target-slider .target-slider-img {
    /* width:100%; */
    width:90px;
    height:90px;
  }
@media (max-width: 768px) {
.info-detail {
  padding:10px;
}

.target-content {
  padding:0;
}

.target-item {
  width: 100%;
  margin:20px 0px;
  font-size:18px;
}

.target-slider-desc {
  font-size:16px;
}

.case-target {
   padding:10px;
}
}
</style>